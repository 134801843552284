<template>
    <div class="auth-wrapper auth-v2">
        <b-overlay
            :show="$store.state.app.shallShowOverlay"
            rounded="sm"
            :bg-color="'transparent'"
            :opacity="0.65"
            :fixed="true"
            :no-wrap="true"
            :z-index="10001"
            class="h-100 bg-transparent"
        />
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    fluid
                    :src="logoImg"
                    width="120"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="7"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Register"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Register-->
            <b-col
                lg="5"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <div class="sk-picker">
                    <b-form-select
                        v-model="locale"
                        :options="cultures"
                        label="text"
                    />
                </div>
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >
                    <b-card-title
                        class="mb-1"
                        title-tag="h2"
                    >
                        {{i18nT(`Welcome to Skileo!`)}} 👋
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{i18nT(`The software for your company and your people!`)}}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer
                        ref="registerForm"
                    >
                        <b-form
                            class="auth-register-form mt-2"
                            @submit.prevent="register"
                        >

                            <!-- HIDE SELECTOR FOR ACCOUNT TYPE -->
                            <b-row v-if="false" class="mb-1">
                                <b-col lg="12">
                                    <b-form-group
                                        :label="i18nT(`Account type`)"
                                        label-for="register-firstname"
                                    >
                                        <b-form-select
                                            v-model="userType"
                                            :options="userTypeOptions"
                                        />
                                    </b-form-group>
                                </b-col>
                            </b-row>


                            <b-row>
                                <b-col
                                    lg="6"
                                >
                                    <!-- firstname -->
                                    <b-form-group
                                        :label="i18nT(`First name`)"
                                        label-for="register-firstname"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Firstname"
                                            vid="firstname"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="register-firstname"
                                                v-model="firstname"
                                                name="register-firstname"
                                                class="required"
                                                :state="errors.length > 0 ? false:null"
                                                :placeholder="i18nT(`Anna`)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                                <b-col
                                    lg="6"
                                >
                                    <!-- lastname -->
                                    <b-form-group
                                        :label="i18nT(`Last name`)"
                                        label-for="register-lastname"
                                        class="required"
                                    >
                                        <validation-provider
                                            #default="{ errors }"
                                            name="Lastname"
                                            vid="lastname"
                                            rules="required"
                                        >
                                            <b-form-input
                                                id="register-lastname"
                                                v-model="lastname"
                                                name="register-lastname"
                                                :state="errors.length > 0 ? false:null"
                                                :placeholder="i18nT(`Bell`)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </b-col>
                            </b-row>

                            <!-- company -->
                            <b-form-group
                                :label="i18nT(`Company`)"
                                label-for="register-company"
                                v-if="userType== 'Client'"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Company"
                                    vid="company"
                                    rules="required"
                                >
                                    <b-form-input
                                        id="register-company"
                                        v-model="company"
                                        name="register-company"
                                        :state="errors.length > 0 ? false:null"
                                        :placeholder="i18nT(``)"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group
                                :label="i18nT(`Phone number`)"
                                label-for="register-phone"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Phone number"
                                    vid="phone"
                                    :rules="{ validPhoneNumber: mazphoneObj }"
                                >
                                    <MazPhoneNumberInput
                                        v-model="phone"
                                        default-country-code="FR"
                                        @update="updatePhoneObj"
                                        :translations="{
                                          countrySelectorLabel: i18nT(`Country code`),
                                          countrySelectorError: i18nT(`Select a country`),
                                          phoneNumberLabel: i18nT(`Phone number`),
                                          example: i18nT(`Example:`),
                                        }"
                                    />
                                    <small class="text-danger">{{ i18nT(errors[0]) }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- email -->
                            <b-form-group
                                :label="i18nT(`Email`)"
                                label-for="register-email"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    vid="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="register-email"
                                        v-model="userEmail"
                                        name="register-email"
                                        :state="errors.length > 0 ? false:null"
                                        :placeholder="i18nT(`workemail@company.com`)"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- password -->
                            <b-form-group
                                label-for="register-password"
                                :label="i18nT(`Password`)"
                                class="required"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="password"
                                    rules="required|passwordRule"
                                >
                                    <b-input-group
                                        class="input-group-merge required"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-input
                                            id="register-password"
                                            v-model="password"
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-password"
                                            :placeholder="i18nT(`············`)"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                :icon="passwordToggleIcon"
                                                class="cursor-pointer"
                                                @click="togglePasswordVisibility"
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <div class="password-rules font-small-3">
                                <div class="pb-1">{{i18nT(`To ensure that your account and information is well protected, please chose a password that has: `)}}</div>
                                <div class="d-flex">
                                    <div class="rule-column pr-2">
                                        <div :class="{ 'rule-met': isLengthValid }"><font-awesome-icon :icon="isLengthValid ? `check` : `times`" class="mr-1" :class="isLengthValid ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`Minimum 8 characters`)}}</div>
                                        <div :class="{ 'rule-met': hasUppercase }"><font-awesome-icon :icon="hasUppercase ? `check` : `times`" class="mr-1" :class="hasUppercase ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one uppercase letter`)}}</div>
                                    </div>
                                    <div class="rule-column">
                                        <div :class="{ 'rule-met': hasNumber }"><font-awesome-icon :icon="hasNumber ? `check` : `times`" class="mr-1" :class="hasNumber ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one number`)}}</div>
                                        <div :class="{ 'rule-met': hasSpecialCharacter }"><font-awesome-icon :icon="hasSpecialCharacter ? `check` : `times`" class="mr-1" :class="hasSpecialCharacter ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one special character`)}}</div>
                                    </div>
                                </div>
                                <!-- Add other rules as needed -->
                            </div>


                            <b-form-group class="mt-2">
                                <validation-provider
                                    #default="{ errors }"
                                    :name="i18nT(`Terms and conditions`)"
                                    vid="status"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="errors.length > 0 ? 'is-invalid':null"
                                    >
                                        <b-form-checkbox
                                            id="register-privacy-policy"
                                            v-model="status"
                                            name="terms"
                                            class="pl-2"

                                        >
                                            {{i18nT(`I accept Skileo's`)}} <b-link :href="`https://www.skileo.com/`+locale+`/privacy`" target="_blank">{{i18nT(`Privacy policy`)}}</b-link> {{i18nT(`and`)}} <b-link :href="`https://www.skileo.com/`+locale+`/terms`"  target="_blank">{{i18nT(`Terms and conditions`)}}</b-link>.<sup class="text-danger">*</sup>
                                        </b-form-checkbox>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-input-group>
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                variant="primary"
                                block
                                type="submit"
                                :disabled="loading"
                            >
                                {{i18nT(`Sign-up`)}}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <span>{{i18nT(`Already have an account?`)}} </span>
                        <b-link :to="{name:'auth-login', query: {lang: locale}}">
                            <span> {{i18nT(`Sign in instead`)}}</span>
                        </b-link>
                    </p>

                    <b-card-text class="text-center mt-2">
                        <b-img
                            fluid
                            :src="gdprImg"
                            class="d-inline mr-50"
                            width="80"
                        />

                        <b-img
                            fluid
                            :src="sslImg"
                            class="d-inline"
                            width="80"
                        />
                    </b-card-text>

                    <!-- divider -->
                    <div class="divider my-2" v-if="false">
                        <div class="divider-text">
                            {{i18nT(`or`)}}
                        </div>
                    </div>

                    <b-button
                        variant="outline-google"
                        block
                        type="submit"
                        v-if="false"
                    >
                        <b-img
                            fluid
                            :src="googleImg"
                            class="mr-1"
                            width="18"
                        />
                        <!--<span class="icomoon-google"></span>-->
                        {{i18nT(`Connect with`)}} {{i18nT(`Google`)}}
                    </b-button>

                    <b-button
                        variant="outline-linkedin"
                        block
                        type="submit"
                        v-if="false"
                    >
                        <b-img
                            fluid
                            :src="linkedinImg"
                            class="mr-1"
                            width="18"
                        />
                        <!--<span class="icomoon-linkedin1"></span>-->
                        {{i18nT(`Connect with`)}} {{i18nT(`Linkedin`)}}
                    </b-button>
                </b-col>
            </b-col>
            <!-- /Register-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
    BRow,
    BCol,
    BLink,
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BCardTitle,
    BCardText,
    BFormSelect,
    BOverlay
} from 'bootstrap-vue'
import { required, email, passwordRule } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'

// import useAuth from '@/auth/useAuth'
import {BASE_URL, API_KEY} from '@core/utils/constants'
import axios from "axios";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {dictToSelectArray} from "@core/utils/utils";
import {isUserLoggedIn} from "@/auth/utils";

extend('validPhoneNumber', {
    params: ['phoneNumberData'],
    validate: (value, maz) => {
        return maz.phoneNumberData.isValid ;
    },
    message: `Invalid phone number`,
});


export default {
    components: {
        BOverlay,
        BRow,
        BImg,
        BCol,
        BLink,
        BButton,
        BForm,
        BCardText,
        BCardTitle,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        // validations
        ValidationProvider,
        ValidationObserver,
        BFormSelect
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: '',
            firstname: '',
            lastname: '',
            userEmail: '',
            password: '',
            loading: false,
            sideImg: require('@/assets/images/pages/register.svg'),
            logoImg: require('@/assets/images/logo/logo_color.svg'),
            googleImg: require('@/assets/images/icons/google_G_logo.svg'),
            linkedinImg: require('@/assets/images/icons/linkedIn_logo.svg'),
            gdprImg: require('@/assets/images/icons/gdpr.svg'),
            sslImg: require('@/assets/images/icons/ssl.svg'),
            // validation
            required,
            email,
            passwordRule,
            company: '',
            phone: '',
            userType: 'Client',
            lang: 'en',
            userTypeOptions: [{
                value: 'Freelancer',
                text: 'Candidate/Employee',
            },
                {
                    value: 'Client',
                    text: 'Employer',
                }],
            locale: 'en',
            cultures: [],
            mazphoneObj: {},
        }
    },
    created() {
        extend('passwordRule', {
            validate(value) {
                // Implement your validation logic here
                return value.length >= 8 && /[A-Z]/.test(value) && /\d/.test(value); // Example criteria
            },
            message: this.i18nT(`Password does not meet requirements`),
        });


        if (isUserLoggedIn()) {
            this.$router.replace('/')
            return;
        }

        this.getCultures();

    },
    computed: {
        isLengthValid() {
            return this.password.length >= 8;
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasSpecialCharacter() {
            return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password);
        },
        hasNumber() {
            return /\d/.test(this.password);
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/register.svg')
                return this.sideImg
            }
            return this.sideImg
        },
    },
    methods: {
        updatePhoneObj(dataCollected){
            this.mazphoneObj = dataCollected;
        },
        getCultures() {
            this.$http.get(`system/cultures`).then(({data}) => {
                this.cultures = dictToSelectArray(data.data)
                if(this.$route.query.lang) {
                    this.locale = this.$route.query.lang;
                }
            })
        },
        register() {

            this.$refs.registerForm.validate().then(success => {
                if (success) {
                    if(!this.status) {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Please agree to Privacy Policy & Terms`),
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        })
                        return;
                    }

                    this.$store.commit('app/REQUEST_PENDING')
                    this.loading = true;

                    axios({
                        method: 'post',
                        url: `${BASE_URL}users/publicRegister`,
                        headers: {
                            'Api-Key': API_KEY,
                            'Content-Type': 'application/json;charset=utf-8',
                        },
                        data: {
                            FirstName: this.firstname,
                            LastName: this.lastname,
                            Email: this.userEmail,
                            Password: this.password,
                            Phone: this.phone,
                            ... this.userType === 'Client' && {Company: this.company},
                            user_type: this.userType,
                            locale: this.locale
                        }
                    }).then(({data}) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Welcome`),
                                icon: 'CoffeeIcon',
                                variant: 'success',
                                text: this.i18nT(data.message),
                            },
                        })
                        this.loading = false;
                        this.$store.commit('app/REQUEST_DONE')
                        this.$router.push('/login')
                    })
                        .catch((error) => {
                            this.loading = false;
                            this.$store.commit('app/REQUEST_DONE')
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.i18nT(error.response.data.message),
                                    icon: 'AlertCircleIcon',
                                    variant: 'danger',
                                },
                            })
                        });
                }
            })
        },
    },
    watch: {
        locale: function (val) {
            this.changeLang(val)
            history.pushState({}, null, `${this.$route.path}?lang=${val}`)
        },
    }
}
/* eslint-disable global-require */
</script>

<style scoped>
.rule-met {
    color: var(--success);
    /* Add a checkmark icon or similar visual indicator */
}



.sk-picker {
    width: 150px;
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
